<template>
  <div class="wrapper-container">
    <div class="describe-container-box">
      <div class="title">美蛙零售数智化业务平台</div>
      <div class="sub-title">一体化十大管理平台</div>
      <img
        class="main-image"
        src="@/image/retail/retail-business-platform@3x.png"
        alt=""
      />
      <div class="introduce">
        全球蛙一体化十大管理平台，集进销存、财务、消费者留存、消费者触达、营销活动推广、线上商城、增加消费者连带销售、增加消费者复购、会员卡权益方案、积分商城等于一体，为企业级客户提供全方位全渠道更高质量的门店管理、数据管理和会员管理。
      </div>
    </div>
    <img
      :class="[[2, 3, 9].includes(i) ? 'large-image-h518' : 'large-image-h570']"
      v-for="(src, i) in enableFuncs"
      :key="src"
      :src="src"
    />
    <div class="func-module-container">
      <div class="func-title">实施案例·应用案例</div>
      <div class="func-sub-title">造悟说助力品牌商打造亿元新爆品</div>
    </div>
    <div class="large-image-container">
      <img
        :class="[i ? 'large-image-h820' : 'large-image-h1042']"
        v-for="(src, i) in funcModules"
        :src="src"
        :key="src"
      />
    </div>
    <action-sheet module="美蛙零售数智化业务平台">
      <template #extra-title>
        <div class="extra-title">相关文章</div>
      </template>
    </action-sheet>
  </div>
</template>

<script>
import enumerableRoutesMixin from "@/mixins/enumerableRoutes";
export default {
  mixins: [enumerableRoutesMixin],
  data: () => ({
    enableFuncs: [
      require("@/image/retail/img_1_iplatform_Intelligence@3x.png"),
      require("@/image/retail/img_2_iplatform_Intelligence@3x.png"),
      require("@/image/retail/img_3_iplatform_Intelligence@3x.png"),
      require("@/image/retail/img_4_iplatform_Intelligence@3x.png"),
      require("@/image/retail/img_5_iplatform_Intelligence@3x.png"),
      require("@/image/retail/img_6_iplatform_Intelligence@3x.png"),
      require("@/image/retail/img_7_iplatform_Intelligence@3x.png"),
      require("@/image/retail/img_8_iplatform_Intelligence@3x.png"),
      require("@/image/retail/img_9_iplatform_Intelligence@3x.png"),
      require("@/image/retail/img_10_iplatform_Intelligence@3x.png"),
    ],
    funcModules: [
      require("@/image/retail/img_mth_Intelligence@3x.png"),
      require("@/image/retail/img_fyxg_Intelligence@3x.png"),
    ],
  }),
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/solution.common.scss";
.main-image {
  margin-bottom: 40px !important;
}
.introduce {
  margin-top: 0 !important;
  margin-bottom: 20px !important;
}
.func-module-container {
  margin-top: 20px;
  padding-bottom: 40px !important;
}
.large-image-container {
  padding-top: 0 !important;
}
.large-image-h820 {
  margin: 40px 0 34px 0;
}
</style>
